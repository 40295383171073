import React, { useState } from "react";
import { DataFiltersDTO, Dimension } from "../DTO/Filters";
import getData from "../api-integration/synthesis/synthesis.service";
import { EntityDTO } from "../DTO/Entity";
import { KQI } from "../DTO/KQI";
import { DataDTO } from "../DTO/Data";
import { Periodicity } from "../api-models/Periodicity";
export interface HistoricalDataContextType {
  data: DataDTO[];
  entity: EntityDTO;
  loading: boolean;
  KQI?: KQI;
  setKQI: (kqi: KQI | undefined) => void;
  dimension?: Dimension;
  setDimension: (d: Dimension) => void;
  filters: DataFiltersDTO;
  periodicity?: Periodicity;
  setPeriodicity: (p: Periodicity) => void;
  refresh?: boolean;
  setRefresh: (v: boolean) => void;
  changeFilters: <K extends keyof DataFiltersDTO>(
    key: K,
    value: DataFiltersDTO[K]
  ) => void;
  resetFilters: () => void;
  resetDimensions: () => void;
}

export const HistoricalDataContext =
  React.createContext<HistoricalDataContextType>(
    {} as HistoricalDataContextType
  );

export const HistoricalDataContextProvider: React.FC<{
  entity: EntityDTO;
}> = ({ children, entity }) => {
  let data = [] as DataDTO[];
  const [refresh, setRefresh] = useState<boolean>(false);
  const [filters, setFilters] = useState<DataFiltersDTO>({});
  const [dimension, setDimension] = useState<Dimension | undefined>();
  const [KQI, setKQI] = useState<KQI | undefined>();
  const [periodicity, setPeriodicity] = useState<Periodicity | undefined>();

  if (KQI) data = getData(KQI, filters);
  const loading = false;
  return (
    <HistoricalDataContext.Provider
      value={{
        data,
        entity,
        filters,
        loading,
        dimension,
        setDimension,
        KQI,
        setKQI,
        periodicity,
        setPeriodicity,
        refresh,
        setRefresh,
        changeFilters: <K extends keyof DataFiltersDTO>(
          key: K,
          filterValue: DataFiltersDTO[K]
        ) => {
          setFilters((f) => ({ ...f, [key]: filterValue }));
        },
        resetFilters: () => {
          setDimension(undefined);
          setFilters(() => ({}));
          setPeriodicity(undefined);
        },
        resetDimensions: () => {
          setFilters(() => ({}));
        },
      }}
    >
      {children}
    </HistoricalDataContext.Provider>
  );
};
