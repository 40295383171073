import { Avatar, useTheme } from "@material-ui/core";
import React from "react";
import { KQI, Status } from "../../DTO/KQI";

interface ThresholdAvatarProps {
  value: number;
  KQI: KQI;
  status: Status;
}

export const ThresholdAvatar: React.FC<ThresholdAvatarProps> = ({
  value,
  status,
}) => {
  const theme = useTheme();
  return (
    <Avatar
      style={{
        width: theme.spacing(7),
        height: theme.spacing(7),
        padding: "inherit",
        backgroundColor: status,
      }}
    >
      {value}%
    </Avatar>
  );
};
