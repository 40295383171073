export enum EntityType {
  DataProduct = "DataProduct",
  OuputPort = "OutputPort",
}

export interface EntityDTO {
  domain: string;
  dpName: string;
  version: string;
  type: EntityType;
  opName?: string;
}
