import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { TableCellProps } from "./TableContent";
import { WbTableSort, WbTableSortDirection } from "../WbTable";

export interface TableHeaderProps<T> {
  columns: Array<TableCellProps<T>>;
  style?: React.CSSProperties;
  onSort?: WbTableSort;
}

const getNewDirection = (d: WbTableSortDirection | undefined) => {
  switch (d) {
    case "asc":
      return "desc";
    case "desc":
      return "asc";
    default:
      return "asc";
  }
};

export function TableHeader<T>({
  columns,
  style,
  onSort,
}: TableHeaderProps<T>) {
  const theme = useTheme();
  const [active, setActive] = React.useState<string>();
  const [direction, setDirection] = React.useState<WbTableSortDirection>();

  const getTableCellComponent = (cell: TableCellProps<T>) => {
    const title = (
      <Typography color="primary" component="div" variant="button">
        <Box>{cell.headerName}</Box>
      </Typography>
    );

    const component =
      cell.sortable && onSort ? (
        <TableSortLabel
          active={active === cell.headerName}
          direction={direction}
          onClick={(e) => {
            setActive(cell.headerName);
            setDirection((d) => {
              const newDirection = getNewDirection(d);
              onSort(e, cell.headerName || "", newDirection);
              return newDirection;
            });
          }}
        >
          {title}
        </TableSortLabel>
      ) : (
        title
      );

    if (!!cell.headerDescription) {
      return <Tooltip title={cell.headerDescription} children={component} />;
    }

    return component;
  };

  return (
    <TableHead
      style={{ background: theme.palette.background.default, ...style }}
    >
      <TableRow>
        {columns.map((cell, cellIndex) => (
          <TableCell key={`cell-index-${cellIndex}`} {...cell.cellProps}>
            {getTableCellComponent(cell)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
