import {
  Box,
  Typography,
  TypographyProps,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";

export interface WbCardProps {
  title: string;
  icon?: JSX.Element;
  actions?: JSX.Element;
  footer?: JSX.Element;
  leading?: JSX.Element;
  toolsComponents?: JSX.Element;
  statusComponent?: JSX.Element;
  cardClassName?: string;
  cardStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  footerStyle?: React.CSSProperties;
  toolsStyle?: React.CSSProperties;
  typographyProps?: TypographyProps;
}

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 2px 5px #00000029",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.default,
  },
  header: {
    display: "flex",
    alignItems: "center",
    background: "#E0F7FA",
  },
  footer: {
    background: "#E0F7FA",
  },
  headerContent: {
    display: "flex",
    alignItems: "center",
    padding: "12px 16px",
    width: "100%",
  },
  headerTitleContent: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    flex: "1 1 0",
  },
  toolsBar: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    border: "1px solid",
    borderColor: theme.palette.divider,
    borderLeft: "none",
    borderRight: "none",
  },
  statusBar: {
    height: "48px",
    width: "30%",
    borderLeft: "1px solid",
    borderColor: theme.palette.divider,
  },
}));

export const WbCard: React.FC<WbCardProps> = ({
  title,
  icon,
  actions,
  leading,
  toolsComponents,
  statusComponent,
  cardClassName,
  children,
  cardStyle,
  headerStyle,
  footerStyle,
  toolsStyle,
  typographyProps,
  footer,
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.card, cardClassName)} style={cardStyle}>
      <Box className={classes.header} style={headerStyle}>
        {leading}
        <Box className={classes.headerContent}>
          <Box className={classes.headerTitleContent}>
            <Typography
              color="primary"
              variant="body1"
              style={{
                fontWeight: "bold",
              }}
              {...typographyProps}
            >
              {title}
            </Typography>
            {icon}
          </Box>
          {actions}
        </Box>
      </Box>
      {!!toolsComponents || !!statusComponent ? (
        <Box className={classes.toolsBar} style={toolsStyle}>
          {!!toolsComponents ? toolsComponents : <Box width="100%" />}
          {!!statusComponent && (
            <Box className={classes.statusBar}>{statusComponent}</Box>
          )}
        </Box>
      ) : (
        <></>
      )}
      {children}
      <Box className={classes.footer} style={footerStyle}>
        {footer}
      </Box>
    </Box>
  );
};
