import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { DefaultTheme } from "@material-ui/styles";
import { createTheme } from "@material-ui/core";
import { MemoryRouter } from "react-router-dom";
import { EntityDTO } from "./DTO/Entity";

export type MicroFrontendContract = {
  containerId: string;
  theme: DefaultTheme;
  token: string;
  parameters: {
    descriptor?: EntityDTO;
    outputport?: any;
  };
};

declare global {
  interface Window {
    renderDataQualityDashboard: (params: MicroFrontendContract) => void;
    unmountDataQualityDashboard: (containerId: string) => void;
  }
}

window.renderDataQualityDashboard = (params) => {
  console.log(params, process.env.OBSERVABILITY_PROXY, `${process.env}`);
  ReactDOM.render(
    <React.StrictMode>
      <MemoryRouter>
        <App theme={params.theme ?? createTheme({})} descriptor={params.parameters?.descriptor!} outputport={params.parameters?.outputport!} />
      </MemoryRouter>
    </React.StrictMode>,
    document.getElementById(params.containerId)
  );
};

window.unmountDataQualityDashboard = (containerId) => {
  const el = document.getElementById(containerId);
  if (!el) {
    return;
  }

  ReactDOM.unmountComponentAtNode(el);
};
