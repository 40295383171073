import React from "react";
import { useTheme } from "@material-ui/core";
import { WbCard, WbCardProps } from "../WbCard/WbCard";

export const WbWidget: React.FC<WbCardProps> = ({
  children,
  cardStyle,
  headerStyle,
  ...props
}) => {
  const theme = useTheme();

  return (
    <WbCard
      headerStyle={{
        background: "#F5F5F5",
        height: "45px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        ...headerStyle,
      }}
      cardStyle={{
        boxShadow: "none",
        border: `1px solid ${theme.palette.divider}`,
        ...cardStyle,
      }}
      typographyProps={{
        variant: "body2",
      }}
      {...props}
    >
      {children}
    </WbCard>
  );
};
