import { EntityDTO } from "../../DTO/Entity";
import { OverviewResponse } from "../../api-models/Overview";
import { adaptOverviewsToCard } from "./overview.adapter";

export default function getOverviewData(entity: EntityDTO) {


    const responseMock: OverviewResponse = {
        Overviews: [
            {
                "domain": "training",
                "dataproduct": "test_dp",
                "version": "v1.0.0",
                "outputport": "W_C0002",
                "legal_entity": "00000",
                "dt_ref": "2022-06-30",
                "conformity_score": 35.5,
                "robustness_score": 99.6,
                "timeliness_score": 95.3
            }, {
                "domain": "training",
                "dataproduct": "test_dp",
                "version": "v1.0.0",
                "outputport": "W_C0002",
                "legal_entity": "00000",
                "dt_ref": "2023-06-30",
                "conformity_score": 56.5,
                "robustness_score": 100,
                "timeliness_score": 90.3
            }
        ]
    }
    return Promise.resolve(responseMock).then((response: OverviewResponse | null) => response ? adaptOverviewsToCard(response?.Overviews) : response)

}