import { EntityDTO } from "../DTO/Entity";
import { Dimension } from "../DTO/Filters";
import getContributors from "../api-integration/contributors/contributors.service";
import getCriteria from "../api-integration/criteria/criteria.service";
import getOutputPorts from "../api-integration/outputPorts/outputPorts.service";


export function getDimensionOptions(dimension: Dimension, entity: EntityDTO) {

    switch (dimension) {
        case Dimension.CONTRIBUTOR:
            return getContributors(entity)
        case Dimension.OUTPUTPORT:
            return getOutputPorts(entity)
        case Dimension.CRITERIA:
            return getCriteria(entity)
        //TODO getCheck and getTDE of outputport (api)
        case Dimension.CHECK:
            return ['check1', 'check2'];
        case Dimension.TDE:
            return ['tde1', 'tde2'];

        default: return []
    }
}