import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  useTheme,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    minWidth: 120,
  },
}));

export type FilterSelectProps = {
  field?: any;
  id?: string;
  label: string;
  values: string[];
  selectedValue?: string;
  inputRef?: any;
  handleChange: (p: any) => void;
};

export function FilterSelect(props: FilterSelectProps) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id={props.label} style={{ fontSize: 16 }}>
        {props.label}
      </InputLabel>
      <Select
        {...props.field}
        inputRef={props.inputRef}
        labelId={props.label}
        value={props.selectedValue}
        onChange={(p) => props.handleChange(p.target.value)}
      >
        {props.values.map((value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
