import { sampleSize } from "lodash"
import { EntityDTO } from "../../DTO/Entity"

export default function getPeriodicity(entity:EntityDTO) {
    const periodicityAvailable = ['Daily', 'Monthly', 'Yearly']
    const periodicityListResponse = { Periodicities: sampleSize(periodicityAvailable, 2) }

    periodicityListResponse.Periodicities.unshift('All')

    return periodicityListResponse.Periodicities
}