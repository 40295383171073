import { Box, Button } from "@material-ui/core";
import React from "react";
import { FiltersWrapper } from "./FiltersWrapper";
import { ConfirmDialog } from "../ConfirmDialog/ConfirmDialog";

export interface FiltersContainerProps {
  resetFilters: () => void;
}

export function FiltersContainer({ resetFilters }: FiltersContainerProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickResetFilterDialog = () => {
    setOpen(true);
  };

  const handleClose = (action?: boolean) => {
    setOpen(false);

    if (action) {
      resetFilters();
    }
  };
  return (
    <Box display="flex" flexDirection="column" padding="12px">
      <Box alignSelf="end" marginBottom={2}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleClickResetFilterDialog}
        >
          Reset Filters
        </Button>
      </Box>
      <ConfirmDialog
        open={open}
        onClose={(value) => handleClose(value)}
      ></ConfirmDialog>
      <FiltersWrapper />
    </Box>
  );
}
