import { DataFiltersDTO } from "../../DTO/Filters";
import { adaptFilters } from "./filters.adapter";
import { KQI as KQIEnum } from "../../DTO/KQI";
import { adaptForSynthesisSection } from "./synthesis.adapter";

export default function getData(KQI: KQIEnum, filters: DataFiltersDTO) {
    //TODO parse
    const body = {
        Filters: adaptFilters(filters),
    };

    console.log(body)
    const dataSynthesis = [
        {
            "ReferenceDate": "2021-04-18",
            "CheckExecutionDate": "2021-04-18",
            "DQCriteria": "Existence",
            "Score": 95.6,
            "RobustnessScore": 93.4,
            "ScoreAttributes": {
                "OutputPort": "customer.my_data_product.v1.0.0_W_C0002",
                "CheckedField": "INT_STRTDT",
                "Check": "00000",
                "LegalEntity": "001",
                "Contributor": "032",
                "ContributorWeight": 96.6,
                "CheckPeriodicity": "Daily"
            }
        },
        {
            "ReferenceDate": "2021-04-19",
            "CheckExecutionDate": "2021-04-19",
            "DQCriteria": "Coverage",
            "Score": 98.9,
            "RobustnessScore": 93.4,
            "ScoreAttributes": {
                "OutputPort": "customer.my_data_product.v1.0.0_W_C0002",
                "CheckedField": "INT_STRTDT",
                "Check": "00000",
                "LegalEntity": "001",
                "Contributor": "032",
                "ContributorWeight": 96.6,
                "CheckPeriodicity": "Daily"
            }
        },
        {
            "ReferenceDate": "2021-04-20",
            "CheckExecutionDate": "2021-04-20",
            "DQCriteria": "Existence",
            "Score": 98.5,
            "RobustnessScore": 93.4,
            "ScoreAttributes": {
                "OutputPort": "customer.my_data_product.v1.0.0_W_C0002",
                "CheckedField": "INT_STRTDT",
                "Check": "00000",
                "LegalEntity": "001",
                "Contributor": "034",
                "ContributorWeight": 96.6,
                "CheckPeriodicity": "Daily"
            }
        },
        {
            "ReferenceDate": "2021-04-20",
            "CheckExecutionDate": "2021-04-20",
            "DQCriteria": "Existence",
            "Score": 55.5,
            "RobustnessScore": 93.4,
            "ScoreAttributes": {
                "OutputPort": "customer.my_data_product.v1.0.0_W_C0002",
                "CheckedField": "INT_STRTDT",
                "Check": "00000",
                "LegalEntity": "001",
                "Contributor": "032",
                "ContributorWeight": 96.6,
                "CheckPeriodicity": "Daily"
            }
        },
        {
            "ReferenceDate": "2021-04-21",
            "CheckExecutionDate": "2021-04-21",
            "DQCriteria": "Existence",
            "Score": 98.5,
            "RobustnessScore": 93.4,
            "ScoreAttributes": {
                "OutputPort": "customer.my_data_product.v1.0.0_W_C0002",
                "CheckedField": "INT_STRTDT",
                "Check": "00000",
                "LegalEntity": "001",
                "Contributor": "034",
                "ContributorWeight": 96.6,
                "CheckPeriodicity": "Daily"
            }
        },
        {
            "ReferenceDate": "2021-04-22",
            "CheckExecutionDate": "2021-04-22",
            "DQCriteria": "Existence",
            "Score": 86.0,
            "RobustnessScore": 93.4,
            "ScoreAttributes": {
                "OutputPort": "customer.my_data_product.v1.0.0_W_C0002",
                "CheckedField": "INT_STRTDT",
                "Check": "00000",
                "LegalEntity": "001",
                "Contributor": "034",
                "ContributorWeight": 96.6,
                "CheckPeriodicity": "Daily"
            }
        },
        {
            "ReferenceDate": "2021-04-22",
            "CheckExecutionDate": "2021-04-22",
            "DQCriteria": "Timeliness",
            "Score": 86.0,
            "RobustnessScore": 93.4,
            "ScoreAttributes": {
                "OutputPort": "customer.my_data_product.v1.0.0_W_C0002",
                "CheckedField": "INT_STRTDT",
                "Check": "00000",
                "LegalEntity": "001",
                "Contributor": "034",
                "ContributorWeight": 96.6,
                "CheckPeriodicity": "Daily"
            }
        },
        {
            "ReferenceDate": "2021-04-23",
            "CheckExecutionDate": "2021-04-23",
            "DQCriteria": "Coverage",
            "Score": 98.5,
            "RobustnessScore": 93.4,
            "ScoreAttributes": {
                "OutputPort": "customer.my_data_product.v1.0.0_W_C0002",
                "CheckedField": "INT_STRTDT",
                "Check": "00000",
                "LegalEntity": "001",
                "Contributor": "036",
                "ContributorWeight": 96.6,
                "CheckPeriodicity": "Daily"
            }
        },
        {
            "ReferenceDate": "2021-04-23",
            "CheckExecutionDate": "2021-04-23",
            "DQCriteria": "Timeliness",
            "Score": 98.5,
            "RobustnessScore": 93.4,
            "ScoreAttributes": {
                "OutputPort": "customer.my_data_product.v1.0.0_W_C0002",
                "CheckedField": "INT_STRTDT",
                "Check": "00000",
                "LegalEntity": "001",
                "Contributor": "036",
                "ContributorWeight": 96.6,
                "CheckPeriodicity": "Daily"
            }
        }

    ]


    return adaptForSynthesisSection(dataSynthesis, KQI);
}
