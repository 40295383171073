import { Box, Button, Grid } from "@material-ui/core";
import { FiltersContainer } from "./FiltersContainer";
import { useHistoricalDataContext } from "../../context/useHistoricalDataContext";
import GraphCard from "./GraphCard";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import { WbWidget } from "../WbWidget/WbWidget";
import { TableCard } from "./TableCard";
import { useCurrentPng } from "recharts-to-png";
import { useCallback } from "react";
import { saveAs } from "file-saver";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { activeFilters, unionByDate } from "../../utilities/data.utility";
import { DataFiltersDTO } from "../../DTO/Filters";
import { KQI as KQIEnum } from "../../DTO/KQI";
import { groupBy, sortBy, union } from "lodash";
import { DataDTO } from "../../DTO/Data";

interface HistoricalDataSectionProps {}

const HistoricalDataSection = (props: HistoricalDataSectionProps) => {
  const { filters, loading, data, resetFilters, KQI } =
    useHistoricalDataContext();

  const dataTable = adaptDataForTable(data, filters, KQI!);
  console.log("table", dataTable);
  const dataChart = adaptToChartData(data, filters, KQI!);
  console.log("chart", dataChart);

  const [getPng, { ref }] = useCurrentPng();

  const downloadGraph = useCallback(async () => {
    const png = await getPng();
    if (png) {
      saveAs(png, "myChart.png");
    }
  }, [getPng]);

  const csvConfig = mkConfig({
    filename: "myTable",
    columnHeaders: union(...dataTable.map((e: any) => Object.keys(e))),
  });

  const downloadCsv = async () => {
    const csv = await generateCsv(csvConfig)(dataTable as any);
    if (csv) {
      download(csvConfig)(csv);
    }
  };

  return (
    <Box
      key="table-card"
      display={KQI ? "flex" : "none"}
      width="100%"
      flexDirection="column"
    >
      <FiltersContainer resetFilters={resetFilters} />
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <WbWidget
            title="Data graph"
            actions={
              <Button
                startIcon={<SystemUpdateAltIcon />}
                variant="contained"
                color="primary"
                size="small"
                onClick={downloadGraph}
              >
                Export
              </Button>
            }
          >
            <GraphCard
              graphRef={ref}
              chartData={dataChart}
              noDataLabel="No data available."
              filters={filters}
              KQI={KQI!}
            />
          </WbWidget>
        </Grid>
        <Grid item xs={12}>
          <WbWidget
            title={"Data table"}
            actions={
              <Button
                startIcon={<SystemUpdateAltIcon />}
                variant="contained"
                color="primary"
                size="small"
                onClick={downloadCsv}
              >
                Export
              </Button>
            }
          >
            <TableCard
              loading={loading}
              tableData={dataTable}
              filters={filters}
              KQI={KQI!}
            />
          </WbWidget>
        </Grid>
      </Grid>
    </Box>
  );
};

function adaptToChartData(
  data: DataDTO[],
  filters: DataFiltersDTO,
  KQI: KQIEnum
) {
  const data2 = data.map((d) => ({
    ...d,
    RefDate: new Date(d.RefDate).getTime(),
  }));
  if (activeFilters(filters)) {
    const res = Object.keys(filters).reduce((acc, key) => {
      const k = key as keyof typeof filters;
      if (filters[k] && filters[k]?.length) {
        const groupedObj = groupBy(data2, key);

        return Object.keys(groupedObj).reduce((acc, key) => {
          if (filters[k]?.includes(key)) {
            acc.push({
              name: key,
              data: unionByDate(groupedObj[key], KQI).map((e: DataDTO) => ({
                RefDate: e.RefDate,
                [KQI]: e[KQI],
              })),
            });
          }
          return acc;
        }, [] as any);
      }
      return acc;
    }, {});
    return res;
  } else {
    return unionByDate(
      data2.map((d) => ({
        RefDate: d.RefDate,
        [KQI]: d[KQI],
      })),
      KQI
    );
  }
}

function adaptDataForTable(data: any, filters: DataFiltersDTO, KQI: KQIEnum) {
  let dataTable: any[];
  if (activeFilters(filters)) {
    dataTable = Object.keys(filters)
      .map((key) => {
        const k = key as keyof typeof filters;
        if (filters[k] && filters[k]?.length) {
          const groupedObj = groupBy(data, key);
          return Object.keys(groupedObj)
            .filter((key) => filters[k]?.includes(key))
            .map((dimension) => {
              return unionByDate(groupedObj[dimension], KQI).map(
                (e: DataDTO) => ({
                  RefDate: e.RefDate,
                  [dimension]: e[KQI],
                })
              );
            })
            .flat();
        }
        return [];
      })
      .flat();
  } else {
    dataTable = data.map((d: any) => ({ RefDate: d.RefDate, [KQI]: d[KQI] }));
  }

  dataTable = dataTable.reduce((acc: any, curr: any) => {
    const index = acc.findIndex((x: any) => x.RefDate === curr.RefDate);
    if (index === -1) {
      acc.push(curr);
    } else {
      acc[index] = { ...acc[index], ...curr };
    }
    return acc;
  }, []);
  return sortBy(dataTable, ["RefDate"]);
}

export default HistoricalDataSection;
