import { makeStyles } from "@material-ui/core";
import React from "react";
import { DataFiltersDTO } from "../../DTO/Filters";
import { TableCellProps, WbTable } from "../WbTable";
import { KQI as KQIEnum } from "../../DTO/KQI";
import { activeFilters } from "../../utilities/data.utility";

import { union } from "lodash";
interface TableCardProps {
  loading: boolean;
  tableData: any;
  filters: DataFiltersDTO;
  KQI: KQIEnum;
}

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: "bold",
  },
  truncated: {
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 2,
    overflow: "hidden",
    display: "-webkit-box",
  },
}));

function HistoricalDataTableColumns(
  filters: DataFiltersDTO,
  KQI: KQIEnum,
  tableData: any
): TableCellProps<any>[] {
  const classes = useStyles();

  const columns: any[] = [];

  if (KQI && !activeFilters(filters)) {
    columns.push(
      {
        field: "RefDate",

        tooltip: true,
        headerName: "Date",
        cellProps: {
          width: "15%",
          size: "small",
          style: { wordBreak: "break-word" },
        },
        typographyProps: {
          component: "p",
          variant: "body2",
          className: classes.truncated,
        },
      },
      {
        field: KQI,
        tooltip: true,
        headerName: KQI,
        cellProps: {
          width: "15%",
          size: "small",
          style: { wordBreak: "break-word" },
        },
        typographyProps: {
          component: "p",
          variant: "body2",
          className: classes.truncated,
        },
      }
    );
  }

  if (activeFilters(filters)) {
    union(...tableData.map((e: any) => Object.keys(e))).map((key) =>
      columns.push({
        field: key,
        tooltip: true,
        headerName: key,
        cellProps: {
          width: "15%",
          size: "small",
          style: { wordBreak: "break-word" },
        },
        typographyProps: {
          component: "p",
          variant: "body2",
          className: classes.truncated,
        },
      })
    );
  }
  return columns as unknown as TableCellProps<any>[];
}

export const TableCard: React.FC<TableCardProps> = ({
  loading,
  tableData,
  filters,
  KQI,
}) => {
  return (
    <>
      <WbTable
        noDataLabel="No data available."
        styles={{
          container: { overflow: "scroll", maxHeight: 300 },
        }}
        stickyHeader
        components={{
          tableLoader: { loading },
          tableContent: {
            columns: HistoricalDataTableColumns(filters, KQI, tableData),
            rows: tableData,
          },
        }}
      ></WbTable>
    </>
  );
};
