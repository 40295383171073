import { Box, Grid, Typography, useTheme } from "@material-ui/core";
import { KQICard } from "./KQICard";
import { EntityDTO } from "../../DTO/Entity";
import { useEffect, useState } from "react";
import getOverviewData from "../../api-integration/overview/overview.service";
import { KQICardDTO } from "../../DTO/Overview";
import { useHistoricalDataContext } from "../../context/useHistoricalDataContext";
import { WbWidget } from "../WbWidget/WbWidget";
import { WbCardContent } from "../WbCard/WbCardContent";

interface OverviewSectionProps {
  entity: EntityDTO;
}

const OverviewSection = ({ entity }: OverviewSectionProps) => {
  const theme = useTheme();
  const { KQI, setKQI, resetFilters, refresh, setRefresh } =
    useHistoricalDataContext();
  const [KQICards, setKQICards] = useState<KQICardDTO[]>([]);
  const [details, setDetails] = useState<any>({});

  const fetchKQICards = async () => {
    const data = await getOverviewData(entity).then((response) => {
      setKQICards(response?.KQICards!);
      setDetails({ dt_ref: response?.dt_ref });
    });

    return data;
  };

  useEffect(() => {
    fetchKQICards();
    setRefresh(false);
    setKQI(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      style={{ gap: theme.spacing(2) }}
    >
      {Object.keys(details).length > 0 && (
        <WbWidget title="Details">
          <WbCardContent
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {details.dt_ref && (
              <Box>
                <Typography style={{ fontStyle: "italic" }} variant="subtitle1">
                  Date Ref
                </Typography>
                <Typography variant="body1">{details?.dt_ref}</Typography>
              </Box>
            )}
          </WbCardContent>
        </WbWidget>
      )}
      <Box>
        {!!KQICards.length ? (
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            style={{
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(2),
            }}
          >
            {KQICards.map((card, i) => (
              <Grid key={i} item>
                <KQICard
                  info={card.info}
                  KQI={card.KQI}
                  currentPercentage={card.currentPercentage}
                  prevPercentage={card.prevPercentage}
                  selected={KQI === card.KQI}
                  onClick={() => {
                    resetFilters();
                    setKQI(card.KQI);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box alignSelf="start">
            <Typography>No data available</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OverviewSection;
