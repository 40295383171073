import { EntityDTO } from "../../DTO/Entity"

export default function getCriteria(entity: EntityDTO) {
    const criteriaAvailable = [
        'Existence',
        'Coverage',
        'Exactness',
        'Coherence',
        'Consistency',
        'Stability',
        'Uniqueness',
    ]
    const criteriaListResponse = { Criteria: criteriaAvailable }

    return criteriaListResponse.Criteria
}