import { Overview_Dt_Ref } from "../../api-models/Overview";
import { KQI, } from "../../DTO/KQI";
import { groupBy, maxBy, minBy } from 'lodash'
import { OverviewDTO } from "../../DTO/Overview";

export function adaptOverviewsToCard(
    overviews: Overview_Dt_Ref[]
): OverviewDTO {
    const kqiByDates = groupByLegalAndDates(overviews);

    return {
        dt_ref: kqiByDates.currentOverview?.dt_ref!,
        KQICards: [
            {
                KQI: KQI.CONFORMITY,
                info: "Measures the level of data quality in terms of completeness, accuracy and integrity through a quantitative indicator an integrate seven different Primary Data Quality Criteria.",
                currentPercentage: kqiByDates.currentOverview?.conformity_score ?? 0,
                prevPercentage: kqiByDates.prevOverview?.conformity_score ?? 0,
            },
            {
                KQI: KQI.TIMELINESS,
                info: "Evaluates the actual data availability compared to expectations or SLAs.",
                currentPercentage: kqiByDates.currentOverview?.timeliness_score ?? 0,
                prevPercentage: kqiByDates.prevOverview?.timeliness_score ?? 0,
            },
            {
                KQI: KQI.ROBUSTNESS,
                info: "Measures how strong and effective the control system can be in detecting significant anomalies in respect of critical data identified.",
                currentPercentage: kqiByDates.currentOverview?.robustness_score ?? 0,
                prevPercentage: kqiByDates.prevOverview?.robustness_score ?? 0,
            },
        ]
    }

}

function groupByLegalAndDates(overviews: Overview_Dt_Ref[]) {

    const groupedOverview = groupBy(overviews, 'legal_entity')
    const result = Object.keys(groupedOverview).map(ov => {
        const currentOverview = maxBy(groupedOverview[ov], 'dt_ref')
        const prevOverview = minBy(groupedOverview[ov], 'dt_ref')

        return { currentOverview, prevOverview }
    })

    //prendo solo la prima legacy per il momento (chiedere come deve funzionare)
    return result[0];
}
