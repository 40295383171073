import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
} from "@material-ui/core";
import React from "react";
import { useHistoricalDataContext } from "../../context/useHistoricalDataContext";
import { EntityType } from "../../DTO/Entity";
import { FilterSelect } from "../FilterSelect/FilterSelect";
import { Dimension } from "../../DTO/Filters";
import getPeriodicity from "../../api-integration/periodicities/periodicities.service";
import { DimensionsSelector } from "../DimensionsSelector/DimensionsSelector";
import { KQI as KQIEnum } from "../../DTO/KQI";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    collapse: {
      width: "100%",
    },
    wrapper: {
      backgroundColor: `${theme.palette.common.white} !important`,
      marginBottom: theme.spacing(2),
      boxShadow: "none !important",
    },
    filterWrapper: {
      "& > div > div": {
        backgroundColor: theme.palette.common.white,
      },
    },
    content: {
      "& > div:first-child": {
        color: theme.palette.common.white,
      },
    },
    selectWrapper: {
      "& div": {
        maxWidth: "100%",
      },
    },
  })
);

export const FiltersWrapper: React.FC = () => {
  const classes = useStyles();

  const {
    periodicity,
    KQI,
    entity,
    dimension,
    setDimension,
    setPeriodicity,
    resetDimensions,
  } = useHistoricalDataContext();
  return (
    <Paper className={classes.wrapper}>
      <Grid container className={classes.filterWrapper}>
        <Grid item sm={12} md={12} className={classes.selectWrapper}>
          <FilterSelect
            id="periodicity"
            label="Periodicity"
            selectedValue={periodicity ?? "All"}
            values={getPeriodicity(entity)}
            handleChange={(v) => setPeriodicity(v)}
          />
        </Grid>
        {/* {KQI !== KQIEnum.TIMELINESS && (
          <Grid item sm={12} md={12} className={classes.selectWrapper}>
            <FilterAutocomplete
              id="criteria"
              label="Criteria"
              value={filters["DQCriteria"] as string[]}
              changeFilters={(v) => changeFilters("DQCriteria", v as string[])}
              optionsEndpoint="endpointTest"
              options={getCriteria(
                entity?.domain,
                entity?.name,
                entity.version
              )}
            />
          </Grid>
        )} */}
        <Grid item sm={12} md={6} className={classes.selectWrapper}>
          <FilterSelect
            id="dimension"
            label="Dimension"
            selectedValue={dimension ?? ""}
            values={getDimension(entity?.type!, KQI!)}
            handleChange={(d) => {
              resetDimensions();
              setDimension(d as unknown as Dimension);
            }}
          />
        </Grid>
        <Grid item sm={12} md={6} className={classes.selectWrapper}>
          <DimensionsSelector />
        </Grid>
      </Grid>
    </Paper>
  );
};

function getDimension(entityType: EntityType, KQI: KQIEnum) {
  const parameters = ["Contributor"];
  if (KQI !== KQIEnum.TIMELINESS) {
    parameters.push("DQCriteria");
  }

  if (entityType === EntityType.DataProduct) {
    parameters.push("OutputPort");
  } else {
    parameters.push("TDE", "Check");
  }
  return parameters;
}
