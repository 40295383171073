import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { createStyles, makeStyles, useTheme } from "@material-ui/core";
import { useCallback, useState } from "react";
import { DataFiltersDTO } from "../../DTO/Filters";
import { KQI as KQIEnum } from "../../DTO/KQI";
import { WbCardContent } from "../WbCard/WbCardContent";
import { activeFilters, getColor } from "../../utilities/data.utility";
import { DateTime } from "luxon";

interface GraphCardProps {
  chartData?: any;
  noDataLabel?: string;
  filters: DataFiltersDTO;
  classes?: any;
  KQI: KQIEnum;
  graphRef: any;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    chartContainer: {
      position: "relative",
      width: "100%",
      marginTop: theme.spacing(2),
      height: "320px",
    },
    containerEmpty: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -100%)",
      padding: "12px 16px",
      borderRadius: "4px",
      background: "#DCDCDC",
      color: "black",
    },
  })
);

function getKQIColorLine(KQI: KQIEnum) {
  switch (KQI) {
    case KQIEnum.CONFORMITY:
      return "#2196f3";
    case KQIEnum.ROBUSTNESS:
      return "#f73378";
    case KQIEnum.TIMELINESS:
      return "#f44336";
  }
}

const GraphCard = ({
  chartData,
  noDataLabel,
  filters,
  KQI,
  graphRef,
}: GraphCardProps) => {
  const [hiddenList, setHidden] = useState<string[]>([]);
  const hasData = chartData && Object.keys(chartData).length > 0;
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleOnClick = useCallback(
    (o) => {
      const { value } = o;
      if (hiddenList.includes(value)) {
        const filteredList = hiddenList.filter((i) => i !== value);
        setHidden([...filteredList]);
      } else {
        setHidden([...hiddenList, value]);
      }
    },
    [hiddenList, setHidden]
  );

  const activeKQILine = () => {
    return (
      <Line
        hide={hiddenList.includes(KQI!)}
        type="linear"
        dataKey={KQI!}
        stroke={getKQIColorLine(KQI!)}
      />
    );
  };

  const dateFormatter = (date: any) => {
    return date !== 0 ? DateTime.fromMillis(date).toISODate()! : "";
  };

  const getData = () => {
    const c = activeFilters(filters)
      ? chartData.reduce((acc: any, curr: any) => {
          acc.push(...curr.data);
          return acc;
        }, [])
      : chartData;
    return c;
  };

  return (
    <WbCardContent key="table-card">
      <div className={classes.chartContainer}>
        {!hasData && (
          <div className={classes.containerEmpty}>
            {noDataLabel ?? "No data available"}
          </div>
        )}
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={chartData} ref={graphRef}>
            <CartesianGrid strokeDasharray="3 3" y={50} />

            <XAxis
              tickCount={10}
              dataKey="RefDate"
              scale="time"
              type="number"
              domain={["dataMin", "dataMax"]}
              allowDuplicatedCategory={false}
              tickFormatter={dateFormatter}
            />
            <YAxis />
            <Tooltip labelFormatter={dateFormatter} />

            <Legend
              height={36}
              layout="horizontal"
              verticalAlign="top"
              align="center"
              wrapperStyle={{
                cursor: "pointer",
              }}
              onClick={handleOnClick}
            />

            {!activeFilters(filters) ? (
              <>
                <Brush
                  data={getData()}
                  height={15}
                  width={300}
                  x={100}
                  alwaysShowText={true}
                  dataKey="RefDate"
                  tickFormatter={dateFormatter}
                />
                {activeKQILine()}
              </>
            ) : (
              chartData?.map((dimension: any) => (
                <Line
                  hide={hiddenList.includes(dimension)}
                  dataKey={KQI}
                  data={dimension.data}
                  name={dimension.name}
                  key={dimension.name}
                  stroke={getColor()}
                />
              ))
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </WbCardContent>
  );
};

export default GraphCard;
