import { Button } from "@material-ui/core";
import HistoricalDataSection from "./components/HistoricalDataSection/HistoricalDataSection";
import OverviewSection from "./components/Overview/OverviewSection";
import { WbCard } from "./components/WbCard/WbCard";
import { WbCardContent } from "./components/WbCard/WbCardContent";
import { useHistoricalDataContext } from "./context/useHistoricalDataContext";
import RefreshIcon from "@material-ui/icons/Refresh";
import { EntityDTO } from "./DTO/Entity";

export type DashboardProps = {
  entity: EntityDTO;
};

export function Dashboard({ entity }: DashboardProps) {
  const { setRefresh } = useHistoricalDataContext();
  return (
    <WbCard
      title="Dashboard"
      actions={
        <Button
          style={{ marginLeft: "8px", alignSelf: "end" }}
          startIcon={<RefreshIcon />}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setRefresh(true)}
        >
          Refresh
        </Button>
      }
    >
      <WbCardContent>
        <OverviewSection entity={entity} />
        <HistoricalDataSection />
      </WbCardContent>
    </WbCard>
  );
}
