import { KQI as KQIEnum } from "../../DTO/KQI";
import { DQCriteria } from "../../api-models/DqCriteria";
import { Score } from "../../api-models/Score";

export function adaptForSynthesisSection(
    data: Score[],
    KQI: KQIEnum,
) {

    const dataAdapted = data.map((d, index) => ({
        id: index,
        RefDate: d.ReferenceDate,
        Conformity: d.Score,
        Timeliness: d.Score,
        Robustness: d.RobustnessScore,
        DQCriteria: d.DQCriteria,
        Contributor: d.ScoreAttributes?.Contributor,
        //contirbutorWeight
        OutputPort: d.ScoreAttributes?.OutputPort,
        //tde, check
    }));

    const result = (KQI === KQIEnum.TIMELINESS) ? dataAdapted.filter((r) => r.DQCriteria === DQCriteria.Timeliness) : dataAdapted
    return result
}

