import {
  Box,
  Paper,
  Theme,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import ForwardIcon from "@material-ui/icons/Forward";
import { KQI } from "../../DTO/KQI";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { green, red } from "@material-ui/core/colors";
import { ThresholdAvatar } from "./ThresholdAvatar";
import { round } from "lodash";
import { getThresholdStatus } from "../../utilities/thresholdKQI.utility";
export interface KQICardProps {
  info?: string;
  KQI: KQI;
  currentPercentage: number;
  prevPercentage?: number;
  selected?: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    upArrowIcon: {
      fontSize: `${theme.typography.h2.fontSize}px !important`,
      transform: "rotate(-90deg)",
      fill: `${green[500]} !important`,
    },
    downArrowIcon: {
      fontSize: `${theme.typography.h2.fontSize}px !important`,
      transform: "rotate(90deg)",
      fill: `${red[500]} !important`,
    },
    customPaper: {
      padding: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "20px",
      cursor: "pointer",
    },
  })
);

function getSignOfDifference(current: number, prev: number) {
  const difference = current - prev;
  let sign;
  if (difference > 0) {
    sign = "positive";
  } else if (difference < 0) {
    sign = "negative";
  } else {
    sign = "equal";
  }

  return { difference, sign };
}

export const KQICard = ({
  KQI,
  info,
  currentPercentage,
  prevPercentage,
  selected,
  onClick,
}: KQICardProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const trend = prevPercentage
    ? getSignOfDifference(currentPercentage, prevPercentage)
    : null;

  return (
    <Paper
      onClick={onClick}
      className={classes.customPaper}
      elevation={selected ? 7 : 3}
      style={{
        backgroundColor: selected
          ? "#E0F7FA"
          : theme.palette.background.default,
      }}
    >
      <Box display="flex" style={{ gap: "4px" }}>
        <Typography variant="h6">{KQI}</Typography>
        {info && (
          <Tooltip title={info}>
            <InfoOutlinedIcon color="primary" />
          </Tooltip>
        )}
      </Box>
      <ThresholdAvatar
        KQI={KQI}
        value={currentPercentage}
        status={getThresholdStatus(KQI, currentPercentage)}
      />
      {prevPercentage ? (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" alignItems="center">
            {trend?.sign !== "equal" && (
              <ForwardIcon
                className={
                  trend?.sign === "negative"
                    ? classes.downArrowIcon
                    : classes.upArrowIcon
                }
              />
            )}

            <Typography variant="body1" style={{ margin: 0 }}>
              {trend?.sign !== "negative" && "+"}
              {round(trend?.difference!)}%
            </Typography>
          </Box>
          <Typography style={{ fontStyle: "italic" }} variant="body2">
            Trend in respect to previous date.
          </Typography>
        </Box>
      ) : (
        <></>
      )}
    </Paper>
  );
};
