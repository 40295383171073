import { DataFiltersDTO } from "../../DTO/Filters";

export function adaptFilters(filters: DataFiltersDTO) {
    return Object.keys(filters).reduce((acc, curr) => {
        const value = filters[curr as keyof typeof filters];
        if (value && value.length > 0) {
            const filter = {
                left_operand: curr,
                operator: typeof (value) === 'string' ? '=' : "in",
                right_operand: value,
            };
            acc.push(filter);
        }

        return acc;
    }, [] as { left_operand: string; operator: string; right_operand: string | string[] }[]);
}
