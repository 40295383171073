import {
  Link,
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";
import clsx from "clsx";
import React from "react";

export interface TableCellProps<T> {
  cellProps?: {
    align?: "inherit" | "center" | "left" | "right" | "justify";
    size?: "small" | "medium";
    width?: string | number;
    style?: React.CSSProperties;
  };
  typographyProps?: {
    color?:
      | "inherit"
      | "initial"
      | "secondary"
      | "primary"
      | "textPrimary"
      | "textSecondary"
      | "error";
    component?: React.ElementType;
    variant?: Variant | "srOnly" | "inherit";
    style?: React.CSSProperties;
    className?: string;
  };
  tooltip?: boolean;
  link?: string;
  field: keyof T;
  headerName?: string;
  headerDescription?: string;
  fieldRender?: (field: T) => React.ReactNode | JSX.Element;
  formatter?: (value: any) => any;
  sortable?: boolean;
}

export interface TableContentProps<T> {
  columns: Array<TableCellProps<T>>;
  rows: Array<T>;
  rowStyle?: React.CSSProperties;
  rowClassName?: string;
  onRowClick?: (
    element: T,
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => void;
  styles?: React.CSSProperties;
}

const useStyles = makeStyles((theme) => ({
  tableRow: {
    position: "relative",
    borderBottom: "1px solid white",
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      backgroundColor: "#eefafc",
    },
  },
}));

interface TableCellComponentProps<T> {
  row: T;
  cell: TableCellProps<T>;
}

const TableCellComponent = <T,>({ row, cell }: TableCellComponentProps<T>) => {
  let component = cell.formatter ? (
    <>{cell.formatter(row[cell.field])}</>
  ) : (
    <>{row[cell.field]}</>
  );

  if (cell.fieldRender && cell.field) {
    return <>{cell.fieldRender(row)}</>;
  }
  if (cell.link && cell.field) {
    component = <Link color="inherit">{row[cell.field]}</Link>;
  }
  if (cell.typographyProps) {
    component = (
      <Typography
        color={cell.typographyProps.color}
        component={cell.typographyProps.component || "span"}
        variant={cell.typographyProps.variant}
        style={cell.typographyProps.style}
        className={cell.typographyProps.className}
      >
        {component}
      </Typography>
    );
  }
  if (!!cell.tooltip && typeof row[cell.field] === "string") {
    return (
      <Tooltip
        title={row[cell.field] as unknown as string}
        children={component}
      />
    );
  }

  return component;
};

export function TableContent<T>({
  columns,
  rows,
  rowStyle,
  rowClassName,
  onRowClick,
  styles,
}: TableContentProps<T>) {
  const classes = useStyles();

  return (
    <TableBody>
      {rows.map((row, index) => (
        <TableRow
          className={clsx(classes.tableRow, rowClassName)}
          key={`table-row-${index}`}
          style={{
            ...(onRowClick ? { cursor: "pointer" } : {}),
            ...rowStyle,
          }}
          onClick={(event) => {
            if (onRowClick) {
              event.preventDefault();
              event.stopPropagation();
              onRowClick(row, event);
            }
          }}
        >
          {columns.map((cell, cellIndex) => {
            return (
              <TableCell key={`cell-index-${cellIndex}`} {...cell.cellProps}>
                <TableCellComponent<T> row={row} cell={cell} />
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </TableBody>
  );
}
