import { DataDTO } from "../DTO/Data";
import { DataFiltersDTO } from "../DTO/Filters";
import { KQI } from "../DTO/KQI";


export const activeFilters = (filters: DataFiltersDTO) => {
    return (
        !!filters?.TDE?.length ||
        !!filters?.Check?.length ||
        !!filters?.Contributor?.length ||
        !!filters?.DQCriteria?.length ||
        !!filters?.OutputPort?.length
    );
};

export function getColor() {
    const color = Math.floor(Math.random() * 16777215).toString(16);
    return `#${color}`;
}

export function unionByDate(data: any[], KQI: KQI) {
    return data.reduce((acc: DataDTO[], curr: DataDTO) => {
        const index = acc.findIndex((d) => d.RefDate === curr.RefDate);
        if (index === -1) {
            acc.push(curr);
        } else {
            acc[index] = {
                ...curr,
                [KQI]: getAvg(acc[index][KQI], curr[KQI])
            };
        }
        return acc;
    }, [] as DataDTO[]);
}


export function getAvg(value1?: number, value2?: number) {
    //se è contributor fare media pesata 
    if (value1 && value2) {
        return (value1 + value2) / 2;
    }
    return value1 ?? value2;
}
