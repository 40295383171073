import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useRef, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useHistoricalDataContext } from "../../context/useHistoricalDataContext";
import { getDimensionOptions } from "../../utilities/dimensions.utility";

const useStyles = makeStyles((theme) => ({
  customButton: {
    margin: theme.spacing(1),
    background: "red",
    borderRadius: 0,
    minWidth: "fit-content",
    padding: theme.spacing(1, 0.5),
  },
  formControl: {
    width: "100%",
    gap: theme.spacing(2),
  },
  formRow: {
    display: "flex",
    margin: 0,
  },
  customError: {
    display: "flex",
    gap: theme.spacing(1),
    margin: theme.spacing(0, 1),
  },
}));

export interface DimensionsSelectorProps {}

export function DimensionsSelector(props: DimensionsSelectorProps) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const DimensionsSelectorSchema = z.object({
    valori: z.array(z.object({ val: z.string() })),
  });

  type DimensionSelector = z.infer<typeof DimensionsSelectorSchema>;

  const { control, setValue, getValues } = useForm<DimensionSelector>({
    defaultValues: { valori: [{ val: "" }] },
    resolver: zodResolver(DimensionsSelectorSchema),
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "valori",
  });

  const inputRefs = useRef<HTMLInputElement[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { changeFilters, dimension, entity } = useHistoricalDataContext();

  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    if (dimension) {
      const opts = getDimensionOptions(dimension, entity);
      setOptions(opts ?? []);
      setValue("valori", [{ val: "" }]);

      const values = getValues().valori;
      changeFilters(
        dimension!,
        values.map((v) => v.val).filter((v) => v !== "") ?? []
      );
    } else {
      setValue("valori", [{ val: "" }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimension]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <form>
        <FormControl className={classes.formControl} ref={containerRef}>
          {fields.map((_item, index) => {
            return (
              <div key={_item.id} style={{ margin: 1 }}>
                <Grid
                  style={{ display: "flex", margin: 0, gap: 2 }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={10}>
                    <Box className={classes.formRow}>
                      <Controller
                        control={control}
                        name={`valori.${index}.val`}
                        render={({ field }) => (
                          <Autocomplete
                            options={options}
                            disabled={!dimension}
                            style={{ width: "100%" }}
                            value={field.value}
                            onChange={(_event, newValue) => {
                              field.onChange(newValue);
                              const values = getValues().valori;
                              changeFilters(
                                dimension!,
                                values
                                  .map((v) => v.val)
                                  .filter((v) => v !== "") ?? []
                              );
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  inputRef={inputRefs}
                                  {...params}
                                  label={dimension}
                                  variant="outlined"
                                />
                              );
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        remove(index);
                        const values = getValues().valori;

                        changeFilters(
                          dimension!,
                          values.map((v) => v.val!)
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </FormControl>
      </form>
      <Box alignSelf="center" marginTop={2}>
        <Button
          disabled={!dimension}
          startIcon={<AddCircleIcon />}
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => {
            append({ val: "" });
          }}
        >
          Add
        </Button>
      </Box>
    </div>
  );
}
