import { EntityDTO, EntityType } from "../DTO/Entity";

export function parseDescriptor(descriptor: any, outputport?: any): EntityDTO {

  if (
    descriptor && (!("domain" in descriptor) || !('name' in descriptor) || !('version' in descriptor) || !("kind" in descriptor)
    )) {
    const descriptorAsString = JSON.stringify(descriptor, null, " ");
    throw new Error(`DASHBOARD - The DP descriptor is missing fields required to render dashboard.\n${descriptorAsString}`);
  }
  if (outputport && !('name' in outputport)) {
    const outputPortAsString = JSON.stringify(outputport, null, " ");
    throw new Error(`DASHBOARD - The OP descriptor is missing fields required to render dashboard.\n${outputPortAsString}`);
  }
  return {
    domain: descriptor?.domain,
    dpName: descriptor?.name,
    type: outputport ? EntityType.OuputPort : EntityType.DataProduct,
    version: descriptor?.version.split("-")[0],
    opName: outputport ? outputport.name : undefined,
  };

}
