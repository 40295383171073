
export interface DataFiltersDTO {
    DQCriteria?: string[];
    Contributor?: string[]
    OutputPort?: string[]
    TDE?: string[]
    Check?: string[]

}


export enum Dimension {
    CRITERIA = "DQCriteria",
    CONTRIBUTOR = "Contributor",
    OUTPUTPORT = "OutputPort",
    CHECK = "Check",
    TDE = "TDE"

}

