import "./App.css";
import { DefaultTheme } from "@material-ui/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { StylesProvider } from "@material-ui/core/styles";
import { HistoricalDataContextProvider } from "./context/HistoricalDataContext";
import { createGenerateClassName } from "@material-ui/core";
import { parseDescriptor } from "./utilities/descriptorParser";
import { Dashboard } from "./Dashboard";

interface Props {
  theme: DefaultTheme;
  descriptor: any;
  outputport?: any;
}

function App({ theme, descriptor, outputport }: Props) {
  const entity = parseDescriptor(descriptor, outputport);

  return (
    <StylesProvider
      injectFirst
      generateClassName={createGenerateClassName({ productionPrefix: "dqd" })}
    >
      <ThemeProvider theme={theme}>
        <div style={{ display: "flex", flexDirection: "column", gap: 30 }}>
          <HistoricalDataContextProvider entity={entity}>
            <Dashboard entity={entity} />
          </HistoricalDataContextProvider>
        </div>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
