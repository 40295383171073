export enum DQCriteria {
    Existence = "Existence",
    Coverage = "Coverage",
    Exactness = "Exactness",
    Coherence = "Coherence",
    Consistency = "Consistency",
    Stability = "Stability",
    Uniqueness = "Uniqueness",
    Timeliness = "Timeliness",
    Conformity = "Conformity"
}