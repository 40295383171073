import { KQI as KQIEnum, Status } from "../DTO/KQI";

export function getThresholdStatus(KQI: KQIEnum, value: number) {
    switch (KQI) {
        case KQIEnum.TIMELINESS: {
            return TimelinessThreshold(value)
        }
        case KQIEnum.CONFORMITY: {
            return ConformityThreshold(value)
        }

        case KQIEnum.ROBUSTNESS: {
            return RobustnessThreshold(value)
        }
    }
}

function ConformityThreshold(value: number) {
    if (value >= 95)
        return Status.OK
    if (value <= 85) return Status.ERROR
    return Status.WARNING
}


function TimelinessThreshold(value: number) {
    if (value === 95)
        return Status.OK
    if (value === 0) return Status.ERROR
    return Status.WARNING
}

function RobustnessThreshold(value: number) {
    if (value >= 80)
        return Status.OK
    if (value < 40) return Status.ERROR
    return Status.WARNING
}