import { sampleSize } from "lodash"
import { EntityDTO } from "../../DTO/Entity"

export default function getOutputPorts(entity: EntityDTO) {
    const outputPortsAvailable = [{
        "Domain": "training",
        "DataProduct": "testDP",
        "DataProductVersion": "v1.0.0",
        "OutputPort": "training.my_data_product.v1.0.0_W_C0002"
    }, {
        "Domain": "finance",
        "DataProduct": "testFinance",
        "DataProductVersion": "v1.0.0",
        "OutputPort": "finance.my_data_product.v1.0.0_W_C0002"
    }, {
        "Domain": "customer",
        "DataProduct": "testCustomer",
        "DataProductVersion": "v1.0.0",
        "OutputPort": "customer.my_data_product.v1.0.0_W_C0002"
    }]

    const outputPortsListResponse = { OutputPorts: sampleSize(outputPortsAvailable, 2) }

    return outputPortsListResponse.OutputPorts.map(o => o.OutputPort)
}