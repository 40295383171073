
export enum KQI {
    CONFORMITY = "Conformity",
    TIMELINESS = "Timeliness",
    ROBUSTNESS = "Robustness"
}

export enum Status {
    WARNING = "#ff9800",
    ERROR = "#f44336",
    OK = "#4caf50"
}



